import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "./../../components/card/card.jsx";
import Swal from "sweetalert2";
import { apiUrl } from "../../config/config.js";

import pdfUrl from "../../assets/pdf/DisponibilidadAgua.pdf";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

function Forms() {
	const [activeTab, setActiveTab] = useState("form");
	const navigate = useNavigate();
	const [formsData, setFormsData] = useState([]);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await fetch(`${apiUrl}/getList&User`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ table: "forms" }),
			});

			if (response.status === 200) {
				const data = await response.json();
				console.log("FORM DATA", data);
				setFormsData(data);
			} else {
				Toast.fire({ icon: "error", title: "Error al obtener los datos" });
			}
		} catch (error) {
			Toast.fire({ icon: "error", title: error.message });
		}
	};

	const handleEdit = () => {
		// Redirigir a la página de edición
		// navigate("/formDetails", { state: { type: "edit", data: formData } });
	};

	const handleDelete = async () => {
		return;
		try {
			const response = await fetch(apiUrl + "/actions", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					action: "delete",
					table: "forms",
					// data: { id: formData.id },
				}),
			});

			const responseData = await response.json();
			if (responseData.ok) {
				Toast.fire({
					icon: "success",
					title: responseData.message,
					background: "#1e2836",
					color: "#fff",
				});
			} else {
				Toast.fire({
					icon: "error",
					title: responseData.message,
					color: "#fff",
					background: "#1e2836",
				});
				throw new Error(responseData.message || "Error al eliminar el formulario");
			}
		} catch (error) {
			Toast.fire({
				icon: "error",
				title: error.message,
				background: "#1e2836",
				color: "#fff",
			});
		}
	};

	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<h1 className="page-header mb-0">Formularios</h1>
				</div>
			</div>

			<div className="mb-3" role="tablist"></div>

			{activeTab === "form" && (
				<Card>
					<div className="tab-content p-4">
						<div className="tab-pane fade show active" id="formTab">
							<div className="table-responsive">
								<table className="table table-hover text-nowrap">
									<thead>
										<tr>
											<th className="pt-0 pb-2">ID</th>
											<th className="pt-0 pb-2">Usuario</th>
											<th className="pt-0 pb-2">Creado En</th>
											<th className="pt-0 pb-2">Formulario</th>
											<th className="pt-0 pb-2">Archivos</th>
											<th className="pt-0 pb-2">Acción</th>
										</tr>
									</thead>
									<tbody>
										{formsData.length === 0 ? (
											<tr>
												<td colSpan="7" className="text-center">
													No hay formularios disponibles.
												</td>
											</tr>
										) : (
											formsData.map((form) => (
												<tr key={form.id}>
													<td className="align-middle">{form.id}</td>
													<td className="align-middle">{form.userName}</td>
													<td className="align-middle">{new Date(form.createdAt).toLocaleDateString()}</td>
													<td className="align-middle">{form.formName}</td>
													<td className="align-middle">
														{form.files
															? JSON.parse(form.files).map((file, index) => (
																	<a
																		key={index}
																		href={file.url}
																		target="_blank"
																		rel="noopener noreferrer"
																		className="d-block">
																		{file.name}
																	</a>
															  ))
															: "No hay archivos disponibles"}
													</td>
													<td className="align-middle">
														<button
															onClick={() => handleEdit(form)}
															className="btn btn-outline-primary btn-sm me-2"
															style={{ marginRight: "0.75rem" }}>
															<i className="fa fa-edit"></i>
														</button>
														<button onClick={() => handleDelete(form.id)} className="btn btn-outline-danger btn-sm">
															<i className="fa fa-trash"></i>
														</button>
													</td>
												</tr>
											))
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</Card>
			)}
		</div>
	);
}

export default Forms;
