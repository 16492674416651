const Menu = [
	{ is_header: true, title: "Navigation" },
	{ path: "/dashboard", icon: "bi bi-grid", title: "Dashboard" },
	//{ path: "/products", icon: "bi bi-bag", title: "Productos" },
	// { path: "/afiliates", icon: "bi bi-shop", title: "Tiendas" },
	//{ path: "/complaints", icon: "bi bi-exclamation-circle", title: "Sugerencias" },
	//{ path: "/recycles", icon: "bi bi-recycle", title: "Reciclajes" },
	//{ path: "/transactions", icon: "bi bi-cash", title: "Transacciones" },
	//{ path: "/map", icon: "bi bi-geo-alt", title: "Mapa" },
	{ path: "/forms", icon: "bi bi-file-pdf", title: "Formularios" },
	{ path: "/complaints", icon: "bi bi-file-pdf", title: "Sugerencias" },
	{ path: "/users", icon: "bi bi-people", title: "Usuarios" },
	{ path: "/adminUsers", icon: "bi bi-person-badge", title: "Administradores" },
];

export default Menu;
