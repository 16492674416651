import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { apiUrl } from "../../config/config.js";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

function ReclamoDetails() {
	const location = useLocation();
	const navigate = useNavigate();
	const type = location.state?.type || "";
	const data = location.state?.data || {};
	const [userID, setUserID] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [claimType, setClaimType] = useState("");
	const [status, setStatus] = useState("");
	const [id, setId] = useState("");

	useEffect(() => {
		console.log("TYPE", type, "DATA", data);
		if (type === "edit" && data) {
			setUserID(data.userID || "");
			setName(data.name || "");
			setDescription(data.description || "");
			setClaimType(data.claimType || "");
			setStatus(data.status || "");
			setId(data.id || "");
		}
	}, [type, data]);

	const validateForm = () => {
		if (!userID || !description || !claimType || !status) {
			Toast.fire({
				icon: "error",
				title: "Todos los campos son obligatorios",
				background: "#1e2836",
			});
			return false;
		}
		return true;
	};

	const handleSubmit = async (event, action = null) => {
		event.preventDefault();
		if (!validateForm()) return;

		try {
			const response = await fetch(`${apiUrl}/actions`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					action: type === "add" ? "create" : action || "update",
					table: "complaints",
					data: {
						userID,
						description,
						claimType,
						status,
						id,
					},
				}),
			});

			const responseData = await response.json();
			if (responseData.ok) {
				Toast.fire({
					icon: "success",
					title: responseData.message,
					background: "#1e2836",
					color: "#fff",
				});
				window.history.back();
			} else {
				throw new Error(responseData.message || "Error al enviar los datos");
			}
		} catch (error) {
			Toast.fire({
				icon: "error",
				title: error.message,
				background: "#1e2836",
				color: "#fff",
			});
		}
	};

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<h1 className="text-center">{type === "add" ? "Agregar Reclamo" : "Actualizar Reclamo"}</h1>
				<div className="mb-3">
					<label className="form-label">
						Usuario <span className="text-danger">*</span>
					</label>
					<input
						type="text"
						className="form-control form-control-lg bg-white bg-opacity-5"
						placeholder="Nombre"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Descripción (Puedes Añadir un breve comentario) <span className="text-danger">*</span>
					</label>
					<textarea
						className="form-control form-control-lg bg-white bg-opacity-5"
						placeholder="Descripción del reclamo"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Tipo <span className="text-danger">*</span>
					</label>
					<select
						className="form-control form-control-lg bg-white bg-opacity-5"
						value={claimType}
						onChange={(e) => setClaimType(e.target.value)}>
						<option value="Denuncias ambientales">Denuncias ambientales</option>
						<option value="Limpieza de vías">Limpieza de vías</option>
						<option value="Mantenimiento de parques">Mantenimiento de parques</option>
						<option value="Recolección de residuos">Recolección de residuos</option>
					</select>
				</div>

				<div className="mb-3">
					<label className="form-label">
						Estado <span className="text-danger">*</span>
					</label>
					<select className="form-control form-control-lg bg-white bg-opacity-5" value={status} onChange={(e) => setStatus(e.target.value)}>
						<option value="En proceso">En proceso</option>
						<option value="Completado">Completado</option>
						<option value="Pendiente">Pendiente</option>
						<option value="Cancelado">Cancelado</option>
					</select>
				</div>

				<div className="mb-3 d-flex justify-content-around ">
					<button type="submit" className="btn btn-outline-theme btn-lg">
						{type === "add" ? "Agregar" : "Actualizar"}
					</button>
					<button onClick={() => navigate("/complaints")} className="btn btn-outline-theme btn-lg">
						Atras
					</button>

					<button
						onClick={(event) => {
							// setType2("delete");
							handleSubmit(event, "delete");
						}}
						className="btn btn-outline-danger btn-lg">
						Borrar
					</button>
				</div>
			</form>
		</div>
	);
}

export default ReclamoDetails;
