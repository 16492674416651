import React, { useEffect, useState } from "react";
import { Card, CardBody, CardExpandToggler } from "./../../components/card/card.jsx";
import Chart from "react-apexcharts";
import { apiUrl } from "../../config/config.js";

function Dashboard() {
	function randomNo() {
		return Math.floor(Math.random() * 60) + 30;
	}

	var data = [
		{
			title: "USUARIOS",
			total: "420",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "33.3% more than last week",
				},
				{
					icon: "far fa-user fa-fw me-1",
					text: "45.5% new visitors",
				},
				{
					icon: "far fa-times-circle fa-fw me-1",
					text: "3.25% bounce rate",
				},
			],
			chartData: [
				{
					name: "Visitors",
					data: [69, 34, 70, 73, 41, 32, 73, 72, 89, 57, 42, 86, 78],
				},
			],
			chartType: "bar",
			chartHeight: 30,
		},
		{
			title: "FORMULARIOS",
			total: "235",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "20.4% more than last week",
				},
				{
					icon: "fa fa-shopping-bag fa-fw me-1",
					text: "33.5% new orders",
				},
				{
					icon: "fa fa-dollar-sign fa-fw me-1",
					text: "6.21% conversion rate",
				},
			],
			chartData: [
				{
					name: "Sales",
					data: [81, 89, 71, 75, 53, 47, 78, 33, 71, 81, 45, 43, 68],
				},
			],
			chartType: "line",
			chartHeight: 30,
		},
		{
			title: "RECLAMOS",
			total: "44",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "59.5% more than last week",
				},
				{
					icon: "fab fa-facebook-f fa-fw me-1",
					text: "45.5% from facebook",
				},
				{
					icon: "fab fa-youtube fa-fw me-1",
					text: "15.25% from youtube",
				},
			],
			chartData: [77, 80, 70],
			chartType: "pie",
			chartHeight: 45,
		},
		{
			title: "REGISTROS DEL MES",
			total: "5",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "5.3% more than last week",
				},
				{
					icon: "far fa-hdd fa-fw me-1",
					text: "10.5% from 'total' usage",
				},
				{
					icon: "far fa-hand-point-up fa-fw me-1",
					text: "2MB per visit",
				},
			],
			chartData: [83, 74, 58, 70, 78],
			chartType: "donut",
			chartHeight: 45,
		},
	];

	const [statsData, setStatsData] = useState([
		{
			title: "USUARIOS",
			total: "420",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "33.3% more than last week",
				},
				{
					icon: "far fa-user fa-fw me-1",
					text: "45.5% new visitors",
				},
				{
					icon: "far fa-times-circle fa-fw me-1",
					text: "3.25% bounce rate",
				},
			],
			chartData: [
				{
					name: "Visitors",
					data: [69, 34, 70, 73, 41, 32, 73, 72, 89, 57, 42, 86, 78],
				},
			],
			chartType: "bar",
			chartHeight: 30,
		},
		{
			title: "FORMULARIOS",
			total: "235",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "20.4% more than last week",
				},
				{
					icon: "fa fa-shopping-bag fa-fw me-1",
					text: "33.5% new orders",
				},
				{
					icon: "fa fa-dollar-sign fa-fw me-1",
					text: "6.21% conversion rate",
				},
			],
			chartData: [
				{
					name: "Sales",
					data: [81, 89, 71, 75, 53, 47, 78, 33, 71, 81, 45, 43, 68],
				},
			],
			chartType: "line",
			chartHeight: 30,
		},
		{
			title: "RECLAMOS",
			total: "44",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "59.5% more than last week",
				},
				{
					icon: "fab fa-facebook-f fa-fw me-1",
					text: "45.5% from facebook",
				},
				{
					icon: "fab fa-youtube fa-fw me-1",
					text: "15.25% from youtube",
				},
			],
			chartData: [77, 80, 70],
			chartType: "pie",
			chartHeight: 45,
		},
		{
			title: "REGISTROS DEL MES",
			total: "5",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "5.3% more than last week",
				},
				{
					icon: "far fa-hdd fa-fw me-1",
					text: "10.5% from 'total' usage",
				},
				{
					icon: "far fa-hand-point-up fa-fw me-1",
					text: "2MB per visit",
				},
			],
			chartData: [83, 74, 58, 70, 78],
			chartType: "donut",
			chartHeight: 45,
		},
	]);
	const [serverData, setServerData] = useState();
	const [countryData, setCountryData] = useState();
	const [sourceData, setSourceData] = useState();
	const [sourceChartData, setSourceChartData] = useState();
	const [productData, setProductData] = useState();
	const [activityLogData, setActivityLogData] = useState();
	const [chartOptions, setChartOptions] = useState(getChartOptions());

	function getChartOptions() {
		var themeColor = getComputedStyle(document.body).getPropertyValue("--bs-theme").trim();
		var themeColorRgb = getComputedStyle(document.body).getPropertyValue("--bs-theme-rgb").trim();

		return {
			bar: {
				colors: [themeColor],
				chart: { sparkline: { enabled: true } },
				tooltip: {
					x: { show: false },
					y: {
						title: {
							formatter: function (seriesName) {
								return "";
							},
						},
					},
				},
			},
			line: {
				colors: [themeColor],
				chart: { sparkline: { enabled: true } },
				stroke: { curve: "straight", width: 2 },
				tooltip: {
					x: { show: false },
					y: {
						title: {
							formatter: function (seriesName) {
								return "";
							},
						},
					},
				},
			},
			pie: {
				colors: ["rgba(" + themeColorRgb + ", 1)", "rgba(" + themeColorRgb + ", .75)", "rgba(" + themeColorRgb + ", .5)"],
				chart: { sparkline: { enabled: true } },
				stroke: { show: false },
				tooltip: {
					x: { show: false },
					y: {
						title: {
							formatter: function (seriesName) {
								return "";
							},
						},
					},
				},
			},
			donut: {
				colors: [
					"rgba(" + themeColorRgb + ", .15)",
					"rgba(" + themeColorRgb + ", .35)",
					"rgba(" + themeColorRgb + ", .55)",
					"rgba(" + themeColorRgb + ", .75)",
					"rgba(" + themeColorRgb + ", .95)",
				],
				chart: { sparkline: { enabled: true } },
				stroke: { show: false },
				tooltip: {
					x: { show: false },
					y: {
						title: {
							formatter: function (seriesName) {
								return "";
							},
						},
					},
				},
			},
		};
	}

	useEffect(() => {
		fetchDashboardData();
	}, []);

	const fetchDashboardData = async () => {
		try {
			const response = await fetch(apiUrl + "/dashboardData", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (response.status === 200) {
				let data = await response.json();
				console.log("DASHBOARD DATA", data);
				setActivityLogData(data.customerList);
				setStatsData([
					{
						title: "USUARIOS",
						total: data.customerList ? data.customerList.length : 0,
						// info: [
						// 	{
						// 		icon: "fa fa-chevron-up fa-fw me-1",
						// 		text: "33.3% more than last week",
						// 	},
						// 	{
						// 		icon: "far fa-user fa-fw me-1",
						// 		text: "45.5% new visitors",
						// 	},
						// 	{
						// 		icon: "far fa-times-circle fa-fw me-1",
						// 		text: "3.25% bounce rate",
						// 	},
						// ],
						chartData: [
							{
								name: "Visitors",
								data: [69, 34, 70, 73, 41, 32, 73, 72, 89, 57, 42, 86, 78],
							},
						],
						chartType: "bar",
						chartHeight: 30,
					},
					{
						title: "FORMULARIOS",
						total: data.formsCount[0].count,
						// info: [
						// 	{
						// 		icon: "fa fa-chevron-up fa-fw me-1",
						// 		text: "20.4% more than last week",
						// 	},
						// 	{
						// 		icon: "fa fa-shopping-bag fa-fw me-1",
						// 		text: "33.5% new orders",
						// 	},
						// 	{
						// 		icon: "fa fa-dollar-sign fa-fw me-1",
						// 		text: "6.21% conversion rate",
						// 	},
						// ],
						chartData: [
							{
								name: "Sales",
								data: [81, 89, 71, 75, 53, 47, 78, 33, 71, 81, 45, 43, 68],
							},
						],
						chartType: "line",
						chartHeight: 30,
					},
					{
						title: "RECLAMOS",
						total: data.complaintsCount[0].count,
						// info: [
						// 	{
						// 		icon: "fa fa-chevron-up fa-fw me-1",
						// 		text: "59.5% more than last week",
						// 	},
						// 	{
						// 		icon: "fab fa-facebook-f fa-fw me-1",
						// 		text: "45.5% from facebook",
						// 	},
						// 	{
						// 		icon: "fab fa-youtube fa-fw me-1",
						// 		text: "15.25% from youtube",
						// 	},
						// ],
						chartData: [77, 80, 70],
						chartType: "pie",
						chartHeight: 45,
					},
					{
						title: "REGISTROS DEL MES",
						total: data.customersCount[0].count,
						// info: [
						// 	{
						// 		icon: "fa fa-chevron-up fa-fw me-1",
						// 		text: "5.3% more than last week",
						// 	},
						// 	{
						// 		icon: "far fa-hdd fa-fw me-1",
						// 		text: "10.5% from 'total' usage",
						// 	},
						// 	{
						// 		icon: "far fa-hand-point-up fa-fw me-1",
						// 		text: "2MB per visit",
						// 	},
						// ],
						chartData: [83, 74, 58, 70, 78],
						chartType: "donut",
						chartHeight: 45,
					},
				]);
			}
		} catch (error) {
			console.error("Error al realizar la solicitud:", error);
		}
	};

	return (
		<div>
			<div className="row">
				{statsData &&
					statsData.length > 0 &&
					statsData.map((stat, index) => (
						<div className="col-xl-3 col-lg-6" key={index}>
							<Card className="mb-3">
								<CardBody>
									<div className="d-flex fw-bold small mb-3">
										<span className="flex-grow-1">{stat.title}</span>
										<CardExpandToggler />
									</div>
									<div className="row align-items-center mb-2">
										<div className="col-7">
											<h3 className="mb-0">{stat.total}</h3>
										</div>
										<div className="col-5">
											<div className="mt-n2">
												<Chart
													type={stat.chartType}
													height={stat.chartHeight}
													options={chartOptions[stat.chartType]}
													series={stat.chartData}
												/>
											</div>
										</div>
									</div>
									{/* <div className="small text-inverse text-opacity-50 text-truncate">
										{stat.info.length > 0 &&
											stat.info.map((info, index) => (
												<div key={index}>
													<i className={info.icon}></i> {info.text}
												</div>
											))}
									</div> */}
								</CardBody>
							</Card>
						</div>
					))}

				<div className="col-xl-12">
					<Card className="mb-3">
						<CardBody>
							<div className="d-flex fw-bold small mb-3">
								<span className="flex-grow-1">Ultimos Clientes Registrados este mes</span>
								<CardExpandToggler />
							</div>
							<div className="table-responsive">
								<table className="table table-striped table-borderless mb-2px small text-nowrap">
									<tbody>
										{activityLogData && activityLogData.length > 0 ? (
											activityLogData.map((log, index) => (
												<tr key={index}>
													<td>
														<span className="d-flex align-items-center">
															<i
																className={
																	"bi bi-circle-fill fs-6px me-2 " +
																	(log.highlight ? "text-theme" : "text-inverse text-opacity-30")
																}></i>
															{log.name}
														</span>
													</td>
													<td>
														<small>{log.email}</small>
													</td>
													<td>
														<span
															className={
																"badge d-block rounded-0 pt-5px w-70px " +
																(log.status === "1" ? "bg-theme text-theme-900" : "bg-inverse bg-opacity-25")
															}
															style={{ minHeight: "18px" }}>
															{log.status === "1" ? "Activo" : "Inactivo"}
														</span>
													</td>
													<td>
														<a href="#/" className="text-decoration-none text-inverse">
															<i className="bi bi-check"></i>
														</a>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan="4">No records found</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
