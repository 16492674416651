import React from "react";
import App from "./../app.jsx";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "../auth/components/ProtectedRoute.js";
import Home from "./../pages/home/home.js";
import Dashboard from "./../pages/dashboard/dashboard.js";
import Users from "./../pages/pages/users.js";
import UsersDetails from "./../pages/pages/usersDetails.js";
import AdminUsers from "./../pages/pages/adminUsers.js";
import AdminUsersDetails from "./../pages/pages/adminUsersDetails.js";
import Forms from "../pages/pages/forms.js";
import FormsDetails from "../pages/pages/formsDetails.js";
import Complaints from "../pages/pages/complaints.js";
import ComplaintsDetails from "../pages/pages/complaintDetails.js";
import LoginPage from "../auth/pages/LoginPage.jsx";
import PagesError from "./../pages/pages/error.js";

const AppRoute = [
	{
		path: "",
		element: <App />,
		children: [
			{ path: "login", element: <LoginPage /> },
			{ path: "/", element: <Navigate to="/login" /> },
			{
				path: "/*",
				element: <ProtectedRoute />,
				children: [
					{ path: "dashboard", element: <Dashboard /> },
					{ path: "forms", element: <Forms /> },
					{ path: "formsDetails", element: <FormsDetails /> },
					{ path: "complaints", element: <Complaints /> },
					{ path: "complaintDetails", element: <ComplaintsDetails /> },
					{ path: "users", element: <Users /> },
					{ path: "usersDetails", element: <UsersDetails /> },
					{ path: "adminUsers", element: <AdminUsers /> },
					{ path: "adminUsersDetails", element: <AdminUsersDetails /> },
					{ path: "home", element: <Home /> },
					{ path: "*", element: <PagesError /> },
				],
			},
		],
	},
];

export default AppRoute;
