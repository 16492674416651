import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { apiUrl } from "../../config/config.js";
import { Card } from "../../components/card/card.jsx";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

function Sugerencias() {
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState("complaints");
	const [enlargedImage, setEnlargedImage] = useState(null);
	const [data, setData] = useState([]);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await fetch(`${apiUrl}/getList`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ table: "complaints" }),
			});

			if (response.status === 200) {
				const data = await response.json();
				console.log("COMPLAINTA DATA", data);
				setData(data);
			} else {
				Toast.fire({ icon: "error", title: "Error al obtener los datos" });
			}
		} catch (error) {
			Toast.fire({ icon: "error", title: error.message });
		}
	};

	const handleImageClick = (imageSrc) => {
		setEnlargedImage(imageSrc);
	};

	const handleEdit = (send) => {
		navigate("/complaintDetails", { state: send });
	};

	const handleDelete = async (id) => {
		const confirmDelete = await Swal.fire({
			title: "¿Estás seguro?",
			text: "No podrás recuperar este reclamo!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#d33",
			cancelButtonColor: "#3085d6",
			confirmButtonText: "Sí, eliminar!",
		});

		if (confirmDelete.isConfirmed) {
			try {
				const response = await fetch(`${apiUrl}/actions`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						action: "delete",
						table: "complaints",
						data: { id },
					}),
				});

				const responseData = await response.json();
				if (responseData.ok) {
					Toast.fire({
						icon: "success",
						title: responseData.message,
					});
					fetchData(); // Refresca la lista de reclamos
				} else {
					Toast.fire({
						icon: "error",
						title: responseData.message || "Error al eliminar el reclamo",
					});
				}
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: error.message,
				});
			}
		}
	};

	return (
		<div>
			<h1 className="page-header mb-0">Sugerencias</h1>
			<div className=" mb-3"></div>

			{activeTab === "complaints" && (
				<Card>
					<div className="tab-content p-4">
						<div className="tab-pane fade show active">
							<div className="table-responsive">
								<table className="table table-hover text-nowrap">
									<thead>
										<tr>
											<th>ID</th>
											<th>Usuario</th>
											<th>Imagen</th>
											<th>Descripción</th>
											<th>Tipo</th>
											<th>Estado</th>
											<th>Creado En</th>
											<th>Acción</th>
										</tr>
									</thead>
									<tbody>
										{data.length === 0 ? (
											<tr>
												<td colSpan="8" className="text-center">
													No hay reclamos disponibles.
												</td>
											</tr>
										) : (
											data.map((complaint) => (
												<tr key={complaint.id}>
													<td className="align-middle">{complaint.id}</td>
													<td className="align-middle">{complaint.name}</td>
													<td className="align-middle">
														<img
															src={complaint.image}
															alt="reclamo"
															width="50"
															onClick={() => handleImageClick(complaint.image)}
															style={{ cursor: "pointer" }}
														/>
													</td>

													<td className="align-middle">{complaint.description}</td>
													<td className="align-middle">{complaint.claimType}</td>
													<td className="align-middle">{complaint.status}</td>
													<td className="align-middle">{new Date(complaint.createdAt).toLocaleDateString()}</td>
													<td className="align-middle">
														<button
															onClick={() => {
																handleEdit({ type: "edit", data: complaint });
															}}
															className="btn btn-outline-primary btn-sm me-2"
															style={{ marginRight: "0.75rem" }}>
															<i className="fa fa-edit"></i>
														</button>
														<button
															// onClick={handleDelete}
															className="btn btn-outline-danger btn-sm">
															<i className="fa fa-trash"></i>
														</button>
													</td>
												</tr>
											))
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					{enlargedImage && (
						<div
							style={{
								position: "fixed",
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								backgroundColor: "rgba(0,0,0,0.7)",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								zIndex: 1000,
							}}
							onClick={() => setEnlargedImage(null)}>
							<img
								src={enlargedImage}
								alt="Enlarged view"
								style={{
									maxWidth: "90%",
									maxHeight: "90%",
									objectFit: "contain",
								}}
							/>
						</div>
					)}
				</Card>
			)}
		</div>
	);
}

export default Sugerencias;
