import { apiUrl } from "../../config/config.js";

export const login = async (email, password) => {
	const url = `${apiUrl}/login`;
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ email, password }),
	});
	if (response.ok) {
		const data = await response.json();
		window.localStorage.setItem("authToken", await data.token);
		console.log("RESP AUTH", data);
		return data;
	} else {
		throw new Error("Invalid credentials");
	}
};

export const register = async (name, email, password, idNumber) => {
	const url = `${apiUrl}/register`;
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ name, email, password, idNumber }),
	});
	if (response.ok) {
		console.log("Success");
		const data = await response.json();
		window.localStorage.setItem("authToken", await data.token);
		return data;
	} else {
		throw new Error("Failed to register");
	}
};
