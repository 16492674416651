import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { apiUrl } from "../../config/config.js";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});

function FormsDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const type = location.state?.type || "";
  const data = location.state?.data || {};

  // Define el estado de los campos del formulario
  const [userID, setUserID] = useState("");
  const [formName, setFormName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [pdf, setPdf] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    // Si se edita, precarga los datos en el formulario
    if (type === "edit" && data) {
      setUserID(data.userID || "");
      setFormName(data.formName || "");
      setCreatedAt(data.createdAt || "");
      setPdf(data.pdf || "");
      setId(data.id || "");
    }
  }, [type, data]);

  const validateForm = () => {
    if (!userID || !formName || !createdAt || !pdf) {
      Toast.fire({
        icon: "error",
        title: "Todos los campos son obligatorios",
        background: "#1e2836",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (event, action = null) => {
    event.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await fetch(apiUrl + "/actions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action:
            type === "add" ? "create" : action === null ? "update" : action,
          table: "forms",
          data: {
            userID,
            formName,
            createdAt,
            pdf,
            id,
          },
        }),
      });

      const responseData = await response.json();
      if (responseData.ok) {
        Toast.fire({
          icon: "success",
          title: responseData.message,
          background: "#1e2836",
          color: "#fff",
        });
        window.history.back();
      } else {
        Toast.fire({
          icon: "error",
          title: responseData.message,
          background: "#1e2836",
          color: "#fff",
        });
        throw new Error(responseData.message || "Error al enviar los datos");
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message,
        background: "#1e2836",
        color: "#fff",
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h1 className="text-center">
          {type === "add" ? "Agregar Formulario" : "Actualizar Formulario"}
        </h1>
        <div className="mb-3">
          <label className="form-label">
            Usuario <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5"
            placeholder="Nombre de Usuario"
            value={userID}
            onChange={(e) => setUserID(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            Nombre del Formulario <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5"
            placeholder="Nombre del Formulario"
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            Creado En <span className="text-danger">*</span>
          </label>
          <input
            type="datetime-local"
            className="form-control form-control-lg bg-white bg-opacity-5"
            value={createdAt}
            onChange={(e) => setCreatedAt(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            PDF <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5"
            placeholder="URL del PDF"
            value={pdf}
            onChange={(e) => setPdf(e.target.value)}
          />
        </div>
        <div className="mb-3 d-flex justify-content-around">
          <button type="submit" className="btn btn-outline-theme btn-lg">
            {type === "add" ? "Agregar" : "Actualizar"}
          </button>
          <button
            onClick={() => navigate("/forms")}
            className="btn btn-outline-theme btn-lg"
          >
            Atrás
          </button>
          {type === "edit" && (
            <button
              onClick={(event) => {
                handleSubmit(event, "delete");
              }}
              className="btn btn-outline-danger btn-lg"
            >
              Borrar
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default FormsDetails;
